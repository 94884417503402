import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import BreadCrumb from '../../../components/BreadCrumb';
import {withLocaleProvider} from '../../../locale/LocaleProvider';
import MyIframe from '../../../components/MyIframe';

export const GermanCoursePage = () => {
  const [demo, setDemo] = useState<{url: string; title: string} | undefined>();
  const {t, i18n} = useTranslation();

  return (
    <>
      <Seo
        lang={i18n.language}
        title={t('German-course:seo:title')}
        description={t('German-course:seo:description')}
        url={t('German-course:seo:url')}
      />
      <Layout beforeFooter>
        <BreadCrumb
          routes={[
            {
              name: t('Nav:products:title'),
            },
            {
              name: t('Nav:products:link-1:title'),
              path: t('Nav:products:link-1:url'),
            },
            {
              name: t('Language-courses:contents:card-de:title'),
              path: t('Language-courses:contents:card-de:link-url'),
            },
          ]}
        />
        <div className="breadcrumbs-v3 img-de">
          <div className="container text-center">
            <p />
            <h1 className="no-margin">{t('German-course:intro:title')}</h1>
          </div>
        </div>
        <div className="container content-sm">
          <p
            dangerouslySetInnerHTML={{__html: t('German-course:contents:text')}}
          />
          <div className="margin-bottom-40"></div>
          <div className="headline">
            <h2>{t('German-course:contents:levels:title')}</h2>
          </div>
          <div className="row margin-bottom-20">
            <div className="col-md-6 single-course">
              <h3>{t('German-course:contents:levels:level-1:title')}</h3>
              <span>
                <strong>
                  {t('German-course:contents:levels:level-1:info-level:title')}
                </strong>
                {t('German-course:contents:levels:level-1:info-level:text')}
              </span>
              <span>
                <strong>
                  {t('German-course:contents:levels:level-1:info-demo:title')}
                </strong>
                <button
                  className="button-link"
                  onClick={() =>
                    setDemo({
                      url: t(
                        'German-course:contents:levels:level-1:info-demo:link-url'
                      ),
                      title: t('German-course:seo:title'),
                    })
                  }
                >
                  {t(
                    'German-course:contents:levels:level-1:info-demo:link-text'
                  )}
                </button>
              </span>
              <p>{t('German-course:contents:levels:level-1:text')}</p>
            </div>
            <div className="col-md-6 single-course">
              <h3>{t('German-course:contents:levels:level-2:title')}</h3>
              <span>
                <strong>
                  {t('German-course:contents:levels:level-2:info-level:title')}
                </strong>
                {t('German-course:contents:levels:level-2:info-level:text')}
              </span>
              <span>
                <strong>
                  {t('German-course:contents:levels:level-2:info-demo:title')}
                </strong>
                <button
                  className="button-link"
                  onClick={() =>
                    setDemo({
                      url: t(
                        'German-course:contents:levels:level-2:info-demo:link-url'
                      ),
                      title: t('German-course:seo:title'),
                    })
                  }
                >
                  {t(
                    'German-course:contents:levels:level-2:info-demo:link-text'
                  )}
                </button>
              </span>
              <p>{t('German-course:contents:levels:level-2:text')}</p>
            </div>
            <div className="col-md-6 single-course">
              <h3>{t('German-course:contents:levels:level-3:title')}</h3>
              <span>
                <strong>
                  {t('German-course:contents:levels:level-3:info-level:title')}
                </strong>
                {t('German-course:contents:levels:level-3:info-level:text')}
              </span>
              <span>
                <strong>
                  {t('German-course:contents:levels:level-3:info-demo:title')}
                </strong>
                <button
                  className="button-link"
                  onClick={() =>
                    setDemo({
                      url: t(
                        'German-course:contents:levels:level-3:info-demo:link-url'
                      ),
                      title: t('German-course:seo:title'),
                    })
                  }
                >
                  {t(
                    'German-course:contents:levels:level-3:info-demo:link-text'
                  )}
                </button>
              </span>
              <p>{t('German-course:contents:levels:level-3:text')}</p>
            </div>
            <div className="col-md-6 single-course">
              <h3>{t('German-course:contents:levels:level-4:title')}</h3>
              <span>
                <strong>
                  {t('German-course:contents:levels:level-4:info-level:title')}
                </strong>
                {t('German-course:contents:levels:level-4:info-level:text')}
              </span>
              <span>
                <strong>
                  {t('German-course:contents:levels:level-4:info-demo:title')}
                </strong>
                <button
                  className="button-link"
                  onClick={() =>
                    setDemo({
                      url: t(
                        'German-course:contents:levels:level-4:info-demo:link-url'
                      ),
                      title: t('German-course:seo:title'),
                    })
                  }
                >
                  {t(
                    'German-course:contents:levels:level-4:info-demo:link-text'
                  )}
                </button>
              </span>
              <p>{t('German-course:contents:levels:level-4:text')}</p>
            </div>
          </div>
        </div>
        {demo && (
          <MyIframe
            title={demo.title}
            url={demo.url}
            onClose={() => setDemo(undefined)}
          />
        )}
      </Layout>
    </>
  );
};

export default withLocaleProvider('it', GermanCoursePage);
