import React from 'react';
import {Link} from 'gatsby';
import {useTranslation} from 'react-i18next';

type BreadCrumbProps = {
  routes: {
    name: string;
    path?: string;
  }[];
};

export default function BreadCrumb({routes}: BreadCrumbProps) {
  const {t} = useTranslation();
  return (
    <div className="container container-sm">
      <ul className="breadcrumb custom">
        <li>
          <Link to="/">{t('Nav:home:title')}</Link>
        </li>
        {routes.map((item) => (
          <li key={item.name}>
            {item.path ? (
              <Link to={item.path} activeClassName="active">
                {item.name}
              </Link>
            ) : (
              <a>{item.name}</a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
